// src/pages/HomePage.js
import React from 'react';
import Navbar from '../components/core/Navbar';
import HeroSection from '../components/sections/HeroSection';
import FeatureSection from '../components/sections/FeatureSection';
import Footer from '../components/core/Footer';

const HomePage = () => {
  const heroContent = {
    title: 'Welcome to Numerease',
    subtitle: 'Eliminating the barrier of entry into the Numerai tournament.',
    buttons: [
      { text: 'Learn More', href: '/about' },
      { text: 'Explore Models', href: '/models' },
    ],
    backgroundImage: '/assets/hero-art.png',
  };

  const featureContent = {
    title: 'Why Choose Numerease?',
    features: [
      {
        title: 'Proven Expertise',
        description:
          'Over 8 years of experience building top-performing models with a 71% average return.',
        url: '/models',
      },
      {
        title: 'Automation & Reliability',
        description: 'Automated daily submissions ensure you never miss a deadline.',
        url: '/models',
      },
      {
        title: 'Secure & Scalable',
        description:
          'Your data is encrypted and secure, supporting a wide range of models tailored to your needs.',
        url: '/models',
      },
    ],
  };  


  return (
    <>
      <Navbar />
      <HeroSection {...heroContent} />
      <FeatureSection {...featureContent} />
      <Footer />
    </>
  );
};

export default HomePage;
