import React, { useEffect, useState } from 'react';
import Navbar from '../components/core/Navbar';
import Footer from '../components/core/Footer';
import './ModelsPage.css';

const ModelsPage = () => {
  const [modelData, setModelData] = useState([]);
  const [filteredModels, setFilteredModels] = useState([]);
  const [selectedModel, setSelectedModel] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [sortBy, setSortBy] = useState('mmc_win_rate');
  const [viewMode, setViewMode] = useState('grid'); // 'grid' or 'list'
  const [isFullScreen, setIsFullScreen] = useState(false);

  // Fetch data from models_data.json
  useEffect(() => {
    fetch('/assets/models/models_data.json')
      .then((response) => response.json())
      .then((data) => {
        setModelData(data);
        setFilteredModels(sortModels(data, sortBy));
      })
      .catch((error) => console.error('Error loading model data:', error));
  }, []);

  // Sort models based on selected criteria
  const sortModels = (models, criterion) => {
    return [...models].sort((a, b) => {
      if (criterion === 'mmc_win_rate') return b.mmc_win_rate - a.mmc_win_rate;
      if (criterion === 'corr_win_rate') return b.corr_win_rate - a.corr_win_rate;
      if (criterion === 'active_rounds') return b.active_rounds - a.active_rounds;
      return 0;
    });
  };

  // Handle search and filter
  const handleSearch = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);
    
    const filtered = modelData.filter(model =>
      model.model_name.toLowerCase().includes(query)
    );
    setFilteredModels(sortModels(filtered, sortBy));
  };

  // Handle sort change
  const handleSortChange = (criterion) => {
    setSortBy(criterion);
    setFilteredModels(sortModels(filteredModels, criterion));
  };

  // Model card component
  const ModelCard = ({ model }) => (
    <div 
      className={`model-card ${selectedModel?.model_name === model.model_name ? 'selected' : ''}`}
      onClick={() => setSelectedModel(model)}
    >
      <div className="model-card-header">
        <h3>{model.model_name}</h3>
      </div>
      <div className="model-card-stats">
        <div className="stat">
          <label>MMC Win Rate</label>
          <span>{(model.mmc_win_rate * 100).toFixed(1)}%</span>
        </div>
        <div className="stat">
          <label>CORR Win Rate</label>
          <span>{(model.corr_win_rate * 100).toFixed(1)}%</span>
        </div>
        <div className="stat">
          <label>Active Rounds</label>
          <span>{model.active_rounds}</span>
        </div>
      </div>
    </div>
  );

  // Full-screen modal component
  const VisualizationModal = ({ model, onClose }) => (
    <div className="visualization-modal" onClick={onClose}>
      <div className="modal-content" onClick={e => e.stopPropagation()}>
        <button className="close-modal" onClick={onClose}>&times;</button>
        <h2>{model.model_name} Visualization</h2>
        <img
          src={`/assets/models/${model.model_jpeg}`}
          alt={`${model.model_name} visualization`}
        />
      </div>
    </div>
  );

  return (
    <>
      <Navbar />
      <div className="models-page-content">
        <div className="models-header">
          <div className="title-section">
            <h1>Explore Models</h1>
            <p>Compare and analyze model performance metrics</p>
          </div>
          
          <div className="controls-section">
            <div className="search-box">
              <input
                type="text"
                placeholder="Search models..."
                value={searchQuery}
                onChange={handleSearch}
                className="search-input"
              />
            </div>
            
            <div className="view-controls">
              <select 
                value={sortBy} 
                onChange={(e) => handleSortChange(e.target.value)}
                className="sort-select"
              >
                <option value="mmc_win_rate">Sort by MMC Win Rate</option>
                <option value="corr_win_rate">Sort by CORR Win Rate</option>
                <option value="active_rounds">Sort by Active Rounds</option>
              </select>
              
              <div className="view-toggles">
                <button 
                  className={`view-toggle ${viewMode === 'grid' ? 'active' : ''}`}
                  onClick={() => setViewMode('grid')}
                >
                  Grid
                </button>
                <button 
                  className={`view-toggle ${viewMode === 'list' ? 'active' : ''}`}
                  onClick={() => setViewMode('list')}
                >
                  List
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="models-content">
          <div className={`models-grid ${viewMode}`}>
            {filteredModels.map(model => (
              <ModelCard key={model.model_name} model={model} />
            ))}
          </div>

          {selectedModel && (
            <div className="model-details-panel">
              <div className="model-details">
                <h2>{selectedModel.model_name}</h2>
                <div className="details-grid">
                  <div className="detail-item">
                    <label>Average MMC</label>
                    <span>{selectedModel.avg_mmc.toFixed(4)}</span>
                  </div>
                  <div className="detail-item">
                    <label>Average CORR</label>
                    <span>{selectedModel.avg_corr.toFixed(4)}</span>
                  </div>
                  <div className="detail-item">
                    <label>MMC Win Rate</label>
                    <span>{(selectedModel.mmc_win_rate * 100).toFixed(2)}%</span>
                  </div>
                  <div className="detail-item">
                    <label>CORR Win Rate</label>
                    <span>{(selectedModel.corr_win_rate * 100).toFixed(2)}%</span>
                  </div>
                  <div className="detail-item">
                    <label>Active Rounds</label>
                    <span>{selectedModel.active_rounds}</span>
                  </div>
                </div>
                {selectedModel.model_jpeg && (
                  <div className="model-visualization">
                    <img
                      src={`/assets/models/${selectedModel.model_jpeg}`}
                      alt={`${selectedModel.model_name} visualization`}
                      onClick={() => setIsFullScreen(true)}
                    />
                    <button 
                      className="expand-button"
                      onClick={() => setIsFullScreen(true)}
                      title="View full screen"
                    >
                      <span>⛶</span>
                    </button>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
      {isFullScreen && selectedModel && (
        <VisualizationModal 
          model={selectedModel} 
          onClose={() => setIsFullScreen(false)} 
        />
      )}
      <Footer />
    </>
  );
};

export default ModelsPage;
