/* src/components/core/AboutPage.js */

import React from 'react';
import Navbar from '../components/core/Navbar';
import Footer from '../components/core/Footer';
import './AboutPage.css';

const AboutPage = () => (
  <>
    <Navbar />
    <div className="about-page">
      {/* Hero Section */}
      <section className="about-hero">
        <div
          className="about-hero-background"
          style={{
            backgroundImage: `url('/assets/about_us.png')`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        >
          <div className="container">
            <h1>About Numerease</h1>
            <p className="hero-subheadline">
              Your Partner in Numerai Success
            </p>
          </div>
        </div>
      </section>

      {/* What We Do Section */}
      <section className="about-what">
        <div className="container">
          <h2>What Is Numerease?</h2>
          <p>
            Numerease makes the complexity of participating in the Numerai tournament vanish.
            We run 120 high-performing models that compete daily, tuned for consistent returns.
            Our platform lets you pick the model that fits you—without needing to code,
            analyze metrics, or worry about submissions.
          </p>
        </div>
      </section>

      {/* Why Choose Us Section */}
      <section className="about-why">
        <div className="container">
          <h2>Why Choose Numerease?</h2>
          <ul>
            <li>
              <strong>Proven Performance:</strong> Our models consistently rank in the top tier of the tournament—from 16th to 40th—thanks to years of refinement.
            </li>
            <li>
              <strong>Zero Complexity:</strong> You simply create a Numerai account, connect via API, and choose your model.
              We automate the rest—from daily submissions to model monitoring.
            </li>
            <li>
              <strong>Flexible Options:</strong> Choose a copy of our top model, or diversify with randomized selections pulled from our high-performance set.
            </li>
          </ul>
        </div>
      </section>

      {/* Call-to-Action Section */}
      <section className="about-cta">
        <div className="container">
          <h2>Ready to Compete in Numerai?</h2>
          <p>
            It only takes minutes to start. Join now and take the easiest path into one of the most powerful data science competitions in the world.
          </p>
          <div className="cta-buttons">
            <a href="/get-started" className="cta-button">
              Get Started
            </a>
            <a href="/contact" className="cta-button">
              Contact Us
            </a>
          </div>
        </div>
      </section>
    </div>
    <Footer />
  </>
);

export default AboutPage;
