// src/components/sections/FeatureSection.js
import React from 'react';
import FeatureCard from './FeatureCard';
import './FeatureSection.css';

const FeatureSection = ({ title, features }) => (
  <section id="why-choose-us" className="feature-section">
    <div className="container">
      <h2>{title}</h2>
      <div className="feature-grid">
        {features.map((feature, index) => (
          <FeatureCard
            key={index}
            title={feature.title}
            description={feature.description}
            url={feature.url} // Pass the URL to the FeatureCard
          />
        ))}
      </div>
    </div>
  </section>
);

export default FeatureSection;
