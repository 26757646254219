import React from 'react';
import { useAuth } from '../contexts/AuthContext';
import Navbar from '../components/core/Navbar';
import Footer from '../components/core/Footer';
import './GetStartedPage.css';

const GetStartedPage = () => {
  const { currentUser } = useAuth();

  const steps = [
    {
      title: "Create Numerai Account",
      description: "Sign up at numer.ai and generate your API keys",
      link: "https://numer.ai/signup"
    },
    {
      title: "Grant Access",
      description: "Paste your keys on Numerease to get started",
      link: currentUser ? "/dashboard" : "/login"
    },
    {
      title: "Choose Your Model",
      description: "Select a model or request a custom one — I'll handle the rest",
      link: "/models"
    }
  ];

  return (
    <>
      <Navbar />
      <section className="get-started-section">
        <div className="container">
          <h1>Get Started with Numerease</h1>
          
          <div className="steps-grid">
            {steps.map((step, index) => (
              <div key={index} className="step-card">
                <div className="step-number">{index + 1}</div>
                <h2>{step.title}</h2>
                <p>{step.description}</p>
                <a
                  href={step.link}
                  className="step-button"
                >
                  {index === 1 && currentUser ? "Go to Dashboard" : "Get Started"}
                </a>
              </div>
            ))}
          </div>

          <div className="disclaimer">
            <p>All staking and financial activity happens on numer.ai. Numerease never touches your funds.</p>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default GetStartedPage; 