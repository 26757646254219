// src/components/sections/FeatureCard.js
import React from 'react';
import './FeatureCard.css';

const FeatureCard = ({ title, description, url }) => {
  const content = (
    <div className="feature-card">
      <h3>{title}</h3>
      <p>{description}</p>
    </div>
  );

  return url ? (
    <a href={url} className="feature-card-link">
      {content}
    </a>
  ) : (
    content
  );
};

export default FeatureCard;
