// src/components/sections/HeroSection.js
import React from 'react';
import { Link } from 'react-router-dom';
import './HeroSection.css';

const HeroSection = ({ title, subtitle, buttons }) => {
  return (
    <section 
      className="hero-section"
      style={{ backgroundImage: 'url(/assets/hero-art.png)' }}
    >
      <div className="hero-content">
        <h1>{title}</h1>
        <p>{subtitle}</p>
        <div className="hero-buttons">
          {buttons.map((button, index) => (
            <Link
              key={button.text}
              to={button.href}
              className={`hero-button ${index === 0 ? 'primary' : 'secondary'}`}
            >
              {button.text}
            </Link>
          ))}
        </div>
      </div>
    </section>
  );
};

export default HeroSection;
